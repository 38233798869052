<template>
<div v-if="Access('OrdersView')" class="CookiesMainView">
  <div class="BigPlaceholder P-Rel">
    <!-- Filter Block ------------------------------------------------------------------------------- -->
    <CookiesFilter @CloseMe="ShowFilter = false" :Lists="FilterProps.Lists" :Checks="FilterProps.Checks" :Switches="FilterProps.Switches" :Dates="ArchivedOrders" :ShowFilter="ShowFilter" @DateChanged="DatesChanged()"></CookiesFilter>
    <!-- Main Block ------------------------------------------------------------------------------- -->
    <div class="BigContainer">
      <div class="BigHeader O-754">
        <div v-if="ScanningSelect" class="W-100 H-100 P-Abs D-Flex" style="bottom: 0; background: red; z-index: 500000; border-radius: var(--rad2); background: var(--shade3);">
          <div class="P-Rel W-100 M-Auto D-Flex BG2" style="height: 30%;" :style="'backdrop-filter:' + $store.getters.Theme.BackBlur">
            <div class="M-Auto">
              <div class="color1 F1_5em">{{LanguageSelector('Scan Items To Select', 'امسح الكود')}}</div>
              <input v-model="BarCodeReader" @blur="BarCodeBlurred()" @focus="BarCodeClicked()" @keypress="BarCodeKeyPressed()" :ref="'BarCodeReader'" style="" class="CookiesInput color1">
              <div><button @click="ScanningSelect = false" style="background: var(--colorr1);" class="CookiesButton">Close Scanner</button></div>
            </div>
          </div>
        </div>
        <!-- Multi OrderSelection Code Block ------------------------------------------------------------------------------- -->
        <div v-if="Access('OrdersEdit') & !ArchivedOrders" class="D-Flex M-Auto P-Abs W-100 StatusBar PhoneHider" style="z-index:60; justify-content: flex-end; pointer-events: none;">
          <div class="D-Flex" style="pointer-events: none;">
            <CookiesIcon @click="OpenSelectScanner" class="HoverGlow" style="width:2.2em; padding: 0em 0.2em; height:3.5em; pointer-events: all;" :ID="'Scan'"></CookiesIcon>
            <CookiesIcon @click="SelectAll" class="HoverGlow" style="width:2.2em; padding: 0em 0.2em; height:3.5em; pointer-events: all;" :ID="'Select'"></CookiesIcon>
            <CookiesIcon @click="DeSelectAll" class="HoverGlow" style="width:2.2em; padding: 0em 0.2em; height:3.5em; pointer-events: all;" :ID="'Deselect'"></CookiesIcon>
          </div>
        </div>
        <!-- Action Block ------------------------------------------------------------------------------- -->
          <!-- Loading Indicatr ------------------------------------------------------------------------------- -->
        <div v-if="Loading.length > 0" class="D-Flex M-Auto P-Abs W-100 StatusBar" style="justify-content: center; align-items: center; margin-bottom:-6.5em; margin-left: -0.6em;">
          <div class="D-Flex" style="min-height:2.7em;">
            <div class="M-Auto">
              <CookiesLoading></CookiesLoading>
            </div>
          </div>
        </div>
        <!-- No Buttons Block ------------------------------------------------------------------------------- -->
        <div v-else-if="SelectedOrders.length === 0 & Access('OrdersEdit')" class="D-Flex M-Auto P-Abs W-100 StatusBar PhoneHider" style="z-index: 50; justify-content: center; margin-bottom:-6.5em; margin-left: -0.6em;">
          <div class="ActionButtons IconDisabled">
            <span class="M-Auto">{{LanguageSelector('No Orders Selected', 'لم يتم تحديد اي طلب')}}</span>
          </div>
        </div>
        <!-- Action Buttons Block ------------------------------------------------------------------------------- -->
        <div v-else-if="Access('OrdersEdit')" class="D-Flex M-Auto P-Abs W-100 StatusBar PhoneHider" style="z-index: 50; justify-content: center; margin-bottom:-6.5em; margin-left: -0.6em;">
          <!--  mIXED sTATUS >>>>>>>>> -->
          <div v-if="SameStatus === 'Mix'" class="ActionButtons IconDisabled">
            <span class="M-Auto">{{LanguageSelector('Mixed Status!', 'الطلبات المختارة غير متشابهة')}}</span>
          </div>
          <!--  mIXED sTATUS <<<<<<<<< -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Collecting -->
          <div @click="ChangeSelectedStatus('Collecting')" v-if="['Ordered'].indexOf(SameStatus) >= 0" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Collecting'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('Collecting')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Collecting -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Collected -->
          <div @click="ChangeSelectedStatus('Collected')" v-if="['Collecting'].indexOf(SameStatus) >= 0" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Collected'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('Collected')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Collected -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Received -->
          <div @click="ChangeSelectedStatus('Received')" v-if="['Collected', 'OnDelivery'].indexOf(SameStatus) >= 0" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Received'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('Received')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Received -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Assign Driver -->
          <div @click="ChangeSelectedStatus('AssignDriver')" v-if="['Received', 'Redirected', 'Delayed'].indexOf(SameStatus) >= 0" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'AssignDriver'"></CookiesIcon>
            <span class="M-Auto">{{LanguageSelector('Assign Driver', 'اختر سائق')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Assign Driver -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>OnDelivery -->
          <div @click="ChangeSelectedStatus('OnDelivery')" v-if="['Received', 'Redirected', 'Delayed', 'Canceled', 'Delivered', 'ToBeReturned', 'ReturnedItem', 'ToBeReturnedItem'].indexOf(SameStatus) >= 0 & DriverStatus === 'Assigned' || SameStatus === 'Delivered'" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'OnDelivery'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('OnDelivery')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<OnDelivery -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Delivered -->
          <div @click="ChangeSelectedStatus('Delivered')" v-if="['OnDelivery'].indexOf(SameStatus) >= 0" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Delivered'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('Delivered')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Delivered -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Delayed -->
          <div @click="ChangeSelectedStatus('Delayed')" v-if="['Received', 'Redirected', 'OnDelivery'].indexOf(SameStatus) >= 0" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Delayed'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('Delayed')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Delayed -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Redirected -->
          <div @click="ChangeSelectedStatus('Redirected')" v-if="['Received', 'OnDelivery'].indexOf(SameStatus) >= 0" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Redirected'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('Redirected')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Redirected -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Returned -->
          <div @click="ChangeSelectedStatus('Returned')" v-if="['OnDelivery'].indexOf(SameStatus) >= 0 & SelectedOrders.length === 1" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Returned'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('Returned')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Returned -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Canceled -->
          <div @click="ChangeSelectedStatus('Canceled')" v-if="['Ordered', 'Collecting', 'Collected', 'Received', 'OnDelivery', 'Redirected', 'Delayed'].indexOf(SameStatus) >= 0" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Canceled'"></CookiesIcon>
            <span class="M-Auto">{{StatusMapper('Canceled')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Canceled -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>HasIssue -->
          <div @click="ChangeSelectedStatus('HasIssue')" v-if="SameStatus !== 'None'" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'HasIssue'"></CookiesIcon>
            <span class="M-Auto">{{LanguageSelector('Has Issue', 'يوجد مشكلة')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<HasIssue -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Lost -->
          <!-- <div @click="ChangeSelectedStatus('Lost')" v-if="SameStatus !== 'None'" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Lost'"></CookiesIcon>
            <span class="M-Auto">{{LanguageSelector('Item Lost', 'فقد الطلب')}}</span>
          </div> -->
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Lost -->
          <!--  action button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Deleted -->
          <div @click="ChangeSelectedStatus('Delete')" v-if="Access('OrdersDelete') && SameStatus === 'Received'" class="HoverGlow ActionButtons">
            <CookiesIcon class="CookiesRowIcon" :ID="'Delete'"></CookiesIcon>
            <span class="M-Auto">{{LanguageSelector('Delete', 'حذف')}}</span>
          </div>
          <!--  action button <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Deleted -->
        </div>
        <!-- Main Block Header ------------------------------------------------------------------------------- -->
        <div class="D-Flex OrdersHeader">
          <div class="D-Flex">
            <CookiesIcon :ToolTip="LanguageSelector('Filter', 'فلتر')" class="FilterButton HoverGlow CookiesToolTip" @click="ShowFilter = true" style="width: 2.2em; height: 2.2em; margin: auto 0.5em; padding: 0em 1em;" :ID="'Filter'"></CookiesIcon>
          </div>
          <span class="F-Bold F1_75em BigHeaderText">{{LanguageSelector('Orders', 'الطلبات')}}</span>
          <button v-if="Access('OrdersAdd')" @click="CookiesAddOrder()" :style="'background: ' + $store.getters.Theme.UserColors.main" class="DialogHeaderButton F1_25em">+ {{LanguageSelector('New Order', 'ادخال طلب')}}</button>
          <div class="M-Auto" v-if="IssuesCount > 0">
            <CookiesIcon class="IssueAnimation" style="width: 2.2em; height: 2.2em; padding: 0em 2em;" :ID="'HasIssue'"></CookiesIcon><span class="F-Bold">({{IssuesCount}})</span>
          </div>
        </div>
        <!-- Non/Archived Orders Tab ------------------------------------------------------------------------------- -->
        <CookiesTabs :Router="false" @selected="ChangeOrders" :Default="{ Name: LanguageSelector('Active Orders', 'الطلبات الحالية'), Value: false }" :Tabs="[{ Name: LanguageSelector('Active Orders', 'الطلبات الحالية'), Value: false }, { Name: LanguageSelector('Archived Orders', 'الطلبات المأرشفة'), Value: true }]"></CookiesTabs>
        <!-- Search Block ------------------------------------------------------------------------------- -->
        <div class="D-Flex CookiesSearchInputContainer" :style="$store.getters.Direction === 'ltr' ? 'margin-right: 0em;': 'margin-left: 0em;'">
          <input @blur="SearchNow" @keypress="SearchKey" v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="LanguageSelector('Search...', 'بحث...')">
          <!-- Search Button -->
          <div v-if="Search !== CurrentSearch" @click="SearchNow" class="SearchBarIcon HoverGlow"><CookiesIcon class="M-Auto" style="width: 2em; height: 2em;" :ID="'Search'"></CookiesIcon></div>
          <!-- Cancel Search Button -->
          <div v-if="CurrentSearch !== '' && Search === CurrentSearch" @click="Search = ''; CurrentSearch = ''" class="SearchBarIcon HoverGlow"><CookiesIcon class="M-Auto" style="width: 2em; height: 2em;" :ID="'Removed'"></CookiesIcon></div>
        </div>
      </div>
        <!-- Main Area Block ------------------------------------------------------------------------------- -->
        <div class="BigBody P-Rel">
          <div class="TableBodyContainer">
            <div class=" CookiesRow">
              <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
                <tr class="PhoneHider">  <!-- Header --------------------- -->
                  <th class="PhoneHider"></th>
                  <CookiesSortingTH @click="SortOrders(['ID'])" :Name="'ID'" :Value="['ID']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['BarCode'])" :Name="LanguageSelector('Order Code', 'كود الطلب')" :Value="['BarCode']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['SubmissionDate'])" :Name="LanguageSelector('Date Added', 'تاريخ الطلب')" :Value="['SubmissionDate']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['ExpectedDeliveryDate'])" :Name="LanguageSelector('Expected Date', 'تاريخ التوصيل')" :Value="['ExpectedDeliveryDate']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['Line', 'Name'])" :Name="LanguageSelector('Line', 'الخط')" :Value="['Line', 'Name']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['Area', 'Name'])" :Name="LanguageSelector('Area', 'المنطقة')" :Value="['Area', 'Name']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['Address'])" :Name="LanguageSelector('Address', 'العنوان')" :Value="['Address']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['Client', 'Name'])" :Name="LanguageSelector('Client', 'الزبون')" :Value="['Client', 'Name']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['Customer', 'Company'])" :Name="LanguageSelector('Customer', 'العميل')" :Value="['Customer', 'Company']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['AmountTotal'])" :Name="LanguageSelector('Total', 'اجمالي المطلوب')" :Value="['AmountTotal']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['Fees'])" :Name="LanguageSelector('Delivery Fees', 'عمولة التوصيل')" :Value="['Fees']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortOrders(['Status'])" :Name="LanguageSelector('Status', 'حالة الطلب')" :Value="['Status']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH v-if="!ArchivedOrders" @click="SortOrders(['OrderDriver', 'DriverName'])" :Name="LanguageSelector('Driver', 'السائق')" :Value="['OrderDriver', 'DriverName']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <th>{{LanguageSelector('Payment Status', 'حالة المحاسبة')}}</th>
                  <th></th>
                  <th v-if="Access('OrdersEdit') & !ArchivedOrders" class="Pointer PhoneHider">{{'('+ SelectedOrders.length +')' }}</th>
                </tr>
                 <!-- Selected Header --------------------- -->
                <tr v-if="SelectedOrders.length > 0" class="F-Bold F1_25em O-50"><td colspan="50">{{LanguageSelector('Selected Orders', 'الطلبات المحددة')}}</td></tr>
                 <!-- Selected Items --------------------- -->
                <tr @mouseenter="OverOrder(value.ID)" @mouseleave="LeftOrder(value.ID)" v-for='(value, index) in SelectedOrders' :key='(value, index)' :style="{background: $store.getters.Theme.UserColors.main_25}" class="CookiesGeneralTableRow">
                  <td class="F-Bold PhoneHider" style="padding: 1em 1em;">{{index + 1}}</td>
                  <OrdersRow @RemoveIssue=RemoveIssue :ArchivedOrders="ArchivedOrders" :value="value" :index="index" :OrdersHistory="OrdersHis['Order_' + value.ID]"></OrdersRow>
                </tr>
                 <!-- Selected Seperator --------------------- -->
                <tr v-if="SelectedOrders.length > 0"><td colspan="50"><div class="CookiesRowSplitter"></div></td></tr>
                 <!-- Orders Main  --------------------- -->
                <template  v-for='(value, index) in PageOrders' :key='(value, index)'>
                  <tr @mouseenter="OverOrder(ArchivedOrders ? value.oldOrderId : value.ID)" @mouseleave="LeftOrder(value.ID)" class="CookiesGeneralTableRow">
                    <td class="PhoneHider" style="padding: 1em 1em;">{{index + ((CurrentPage - 1) * OrdersPerPage) + 1}}</td>
                    <OrdersRow @RemoveIssue=RemoveIssue :ArchivedOrders="ArchivedOrders" :value="value" :index="index" :OrdersHistory="OrdersHis['Order_' + (ArchivedOrders ? value.oldOrderId : value.ID)]"></OrdersRow>
                  </tr>
                </template>
                <tr><td colspan="55" style="height: 6em;"></td></tr>
              </table> <!-- TABLE END --------------------- -->
            </div>
            <div class="CookiesTablePager">
              <div v-if="NotSelectedOrders.length > OrdersPerPage && PageOrders.length > 0">
                  <div class="D-Flex" style="direction: ldiv">
                    <CookiesIcon @click="CurrentPage > 1 ? ChangePage(CurrentPage - 1) : ''" class="P-Rel HoverGlow" style="width: 1em; height: 1em; transform: rotate(90deg); margin: auto 2em; float: left;" :ID="'SortingArrow'"></CookiesIcon>
                    <div class="M-Auto D-Flex P-Rel">
                      <span @click="CurrentPage !== 1 ? ChangePage(1) : ''" v-if="CurrentPage > 3" class="F1_25em M-Auto Transition0_25 HoverGlow" style="padding: 0 1em;">1</span>
                      <span v-if="CurrentPage > 3" class="F1_25em M-Auto Transition0_25" style="padding: 0 1em;">...</span>
                      <template v-for="PageNum in Math.ceil(NotSelectedOrders.length / OrdersPerPage)" :key="PageNum">
                        <span @click="ChangePage(PageNum)" :class="CurrentPage === PageNum && 'CurrentPage F-Bold'" v-if="Math.abs(CurrentPage - PageNum) < 3" class="F1_25em M-Auto HoverGlow Transition0_25" style="padding: 0 1em;">{{PageNum}}</span>
                      </template>
                      <span v-if="Math.abs(CurrentPage - Math.ceil(NotSelectedOrders.length / OrdersPerPage)) >= 3" class="F1_25em M-Auto Transition0_25" style="padding: 0 1em;">...</span>
                      <span @click="CurrentPage !== Math.ceil(NotSelectedOrders.length / OrdersPerPage) ? ChangePage(Math.ceil(NotSelectedOrders.length / OrdersPerPage)) : ''" v-if="Math.abs(CurrentPage - Math.ceil(NotSelectedOrders.length / OrdersPerPage)) >= 3" class="F1_25em M-Auto Transition0_25 HoverGlow" style="padding: 0 1em;">{{Math.ceil(NotSelectedOrders.length / OrdersPerPage)}}</span>
                    </div>
                    <CookiesIcon @click="CurrentPage < Math.ceil(NotSelectedOrders.length / OrdersPerPage) ? ChangePage(CurrentPage + 1) : ''" class="P-Rel HoverGlow" style="width: 1em; height: 1em; transform: rotate(-90deg); margin: auto 2em;" :ID="'SortingArrow'"></CookiesIcon>
                  </div>
              </div>
              <div class="OrdersNumber D-Flex">
                <span class="M-Auto F-Bold">{{FilterdTable.length}}<span v-if="!ArchivedOrders" :style="{color: $store.getters.Theme.UserColors.third}">({{SelectedOrders.length}})</span></span>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mounted () {
    if (!this.Access('OrdersView')) {
      if (this.Access('Driver')) {
        this.$router.replace('/DriverPortal')
      }
    }
    this.ReadOrders()
    this.ReadLines()
    this.ReadCustomers()
    this.ReadDrivers()
  },
  data () {
    return {
      ShowFilter: false,
      CurrentOrderHover: '',
      BarCodeReader: '',
      ScanningSelect: false,
      CookiesDataOrders: [],
      CookiesDataArchivedOrders: [],
      CookiesDataCustomers: [],
      CookiesDataDrivers: [],
      Search: '',
      CurrentSearch: '',
      sortElment: {
        name: ['UPDDate'],
        value: -1
      },
      OrdersHis: {},
      CookiesLines: [],
      CurrentPage: 1,
      OrdersPerPage: 100,
      Loading: [],
      ArchivedOrders: false,
      DateFrom: this.$store.state.CurrentDate.From,
      DateTo: this.$store.state.CurrentDate.To
    }
  },
  computed: {
    FilterProps () {
      var FilterLists = [
        {
          Visible: this.Access('StockEdit'),
          Res: 'OrdersLineFilter',
          Selectors: ['Name'],
          Name: this.LanguageSelector('Line', 'الخط'),
          Options: this.CookiesLines
        },
        {
          Visible: this.Access('StockEdit'),
          Res: 'OrdersCustomerFilter',
          Selectors: ['Company'],
          Name: this.LanguageSelector('Customer', 'العميل'),
          Options: this.CookiesDataCustomers
        },
        {
          Visible: this.Access('StockEdit'),
          Res: 'OrdersDriverFilter',
          Selectors: ['Employee', 'Name'],
          Name: this.LanguageSelector('Driver', 'السائق'),
          Options: this.CookiesDataDrivers
        }
      ]
      var FilterChecks = []
      this.Console(this.StatusMapper('', 'objlist'))
      this.StatusMapper('', 'objlist').forEach(element => {
        this.Console('StatusMapper')
        this.Console(element)
        element.Name = element[this.LanguageSelector('ENG', 'ARA')]
        element.Icon = element.value
        element.Res = 'OrdersFilter_' + element.value
        if (!(['DriverPaid', 'CustomerPaid'].indexOf(element.value) >= 0) && (['Done', 'Closed'].indexOf(element.value) >= 0 ? this.ArchivedOrders : !this.ArchivedOrders)) {
          FilterChecks.push(element)
        }
      })
      var FilterSwitches = [
        {
          Visible: !this.ArchivedOrders,
          Name: this.LanguageSelector('Only with issue', 'فقط مع تنبيهات'),
          Icon: 'HasIssue',
          Res: 'OrdersFilter_HasIssue'
        },
        {
          Visible: !this.ArchivedOrders,
          Name: this.LanguageSelector('Only assigned', 'فقط مع سائق'),
          Icon: 'Drivers',
          Res: 'OrdersFilter_Assigned'
        },
        {
          Visible: !this.ArchivedOrders,
          Name: this.LanguageSelector('Driver Paid', 'تم محاسبة السائق'),
          Icon: 'DriverPaid',
          Res: 'OrdersFilter_DriverPaid'
        },
        {
          Visible: !this.ArchivedOrders,
          Name: this.LanguageSelector('Customer Paid', 'تم محاسبة العميل'),
          Icon: 'CustomerPaid',
          Res: 'OrdersFilter_CustomerPaid'
        }
      ]
      return {
        Lists: FilterLists,
        Checks: FilterChecks,
        Switches: FilterSwitches
      }
    },
    FilterdTable () {
      var FilteredArray = []
      this.sortedOrders.forEach(item => {
        // item.Assigned = false
        // if (this.DeepFetcher(item, ['Driver', 'Name']) !== null) {
        //   item.Assigned = true
        // }
        item.HashedID = '#' + (this.ArchivedOrders ? item.oldOrderId : item.ID)
        var FilterIt = true
        try {
          if (this.MEMRead('OrdersLineFilter_Value').ID !== this.DeepFetcher(item, ['Line', 'ID']) && !(['All', 'الكل'].indexOf(this.MEMRead('OrdersLineFilter_Value').Name) >= 0)) {
            FilterIt = false
          }
        } catch (error) {
        }
        try {
          if (this.MEMRead('OrdersCustomerFilter_Value').Id !== this.DeepFetcher(item, ['Customer', 'CustomerID']) && !(['All', 'الكل'].indexOf(this.MEMRead('OrdersCustomerFilter_Value').Company) >= 0)) {
            FilterIt = false
          }
        } catch (error) {
        }
        try {
          if (this.MEMRead('OrdersDriverFilter_Value').Id !== this.DeepFetcher(item, ['OrderDriver', 'DriverID'])) {
            FilterIt = false
          }
        } catch (error) {
        }
        // ////////////////////////////////////////
        if (!this.ArchivedOrders) {
          try {
            if (this.$store.state.CookiesMemory.OrdersFilter_Assigned & (item.OrderDriver === null ? true : (this.DeepFetcher(item, ['OrderDriver', 'Driver']) === null))) {
              FilterIt = false
            }
          } catch (error) {
          }
          try {
            if (this.$store.state.CookiesMemory.OrdersFilter_HasIssue & this.DeepFetcher(item, ['HasIssue']) === false) {
              FilterIt = false
            }
          } catch (error) {
          }
          try {
            if (this.$store.state.CookiesMemory.OrdersFilter_DriverPaid & this.DeepFetcher(item, ['Payment_recived_From_Driver']) === false) {
              FilterIt = false
            }
          } catch (error) {
          }
          try {
            if (this.$store.state.CookiesMemory.OrdersFilter_CustomerPaid & this.DeepFetcher(item, ['Payment_Delivered_To_Cusotmer']) === false) {
              FilterIt = false
            }
          } catch (error) {
          }
        }
        this.StatusMapper('', 'list').forEach(status => {
          try {
            if (!this.$store.state.CookiesMemory['OrdersFilter_' + status] & this.DeepFetcher(item, ['Status']) === status) {
              FilterIt = false
            }
          } catch (error) {
          }
        })
        // //////////////////////////////////////
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.CurrentSearch)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedOrders () {
      var Sorted = []
      if (!this.ArchivedOrders) {
        Sorted = this.CookiesDataOrders
      } else {
        Sorted = this.CookiesDataArchivedOrders
      }
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    SelectedOrders () {
      // var Selected = []
      this.$forceUpdate()
      return this.FilterdTable.filter(element => this.$store.state.CookiesMemory['O_' + element.ID + '_Select'] & !element.Payment_recived_From_Driver & !element.Payment_Delivered_To_Cusotmer)
      // return Selected
    },
    NotSelectedOrders () {
      // var NotSelected = []
      return this.FilterdTable.filter(element => !this.$store.state.CookiesMemory['O_' + element.ID + '_Select'] || element.Payment_recived_From_Driver || element.Payment_Delivered_To_Cusotmer)
      // return NotSelected
    },
    PageOrders () {
      var POrders = this.NotSelectedOrders.slice(((this.CurrentPage - 1) * this.OrdersPerPage), (this.CurrentPage * this.OrdersPerPage))
      return POrders
    },
    IssuesCount () {
      // var NotSelected = []
      return this.sortedOrders.filter(element => element.HasIssue).length
      // return NotSelected
    },
    SameStatus () {
      if (this.SelectedOrders.length > 0) {
        var AllStatus = this.SelectedOrders[0].Status
      } else {
        return 'None'
      }
      this.SelectedOrders.forEach(element => {
        if (element.Status !== AllStatus) {
          AllStatus = 'Mix'
        }
      })
      return AllStatus
    },
    DriverStatus () {
      var AllStat
      var DriverStat = this.DeepFetcher(this.SelectedOrders[0], ['OrderDriver', 'DriverName'])
      if (DriverStat) {
        AllStat = 'Assigned'
      } else {
        AllStat = 'NotAssigned'
      }
      this.$forceUpdate()
      this.SelectedOrders.forEach(element => {
        if (AllStat === 'Assigned') {
          if (!this.DeepFetcher(element, ['OrderDriver', 'DriverName'])) {
            AllStat = 'Mix'
          }
        }
        if (AllStat === 'NotAssigned') {
          if (this.DeepFetcher(element, ['OrderDriver', 'DriverName'])) {
            AllStat = 'Mix'
          }
        }
      })
      return AllStat
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    }
  },
  methods: {
    ReadOrders () {
      this.Loading.push('Loading')
      this.Get('order').then(response => {
        if (response === null) {
          this.Loading.pop()
          return
        }
        if (response !== null & response !== undefined) {
          this.CookiesDataOrders = response
        }
        this.Loading.pop()
      }, error => {
        this.Loading.pop()
        error.CookiesError = 'Error in reading Orders'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadArchivedOrders () {
      this.Loading.push('Loading')
      this.Get('DoneOrders?' + this.DateAPIURL()).then(response => {
        if (response === null) {
          this.Loading.pop()
          return
        }
        if (response !== null & response !== undefined) {
          this.CookiesDataArchivedOrders = response
        }
        this.Loading.pop()
      }, error => {
        this.Loading.pop()
        error.CookiesError = 'Error in reading Archived Orders'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadLines () {
      this.Get('Area/getLine').then(response => {
        if (response === null) {
          this.Loading.pop()
          return
        }
        this.CookiesLines = response
      }, error => {
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomers () {
      this.Get('Customers').then(response => {
        if (response === null) {
          return
        }
        this.CookiesDataCustomers = response
      }, error => {
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDrivers () {
      this.Get('Employee/getdriver').then(response => {
        this.CookiesDataDrivers = response
      }, error => {
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CookiesAddOrder () {
      this.OpenDialog('NewOrder', this.LanguageSelector('New Order', 'طلب جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenOrder (Order) {
      if (!this.Access('OrdersEdit')) {
        return
      }
      this.OpenDialog('EditOrder', this.LanguageSelector('Edit Order', 'تعديل طلب'), Order) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Recover (Order) {
      if (!this.Access('OrdersEdit')) {
        return
      }
      this.Console(Order)
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    OrderHisSorting (a, b) {
      var SortElement = ['Date']
      var SortDirection = 1
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortOrders (name) {
      var Rows = document.getElementsByClassName('CookiesGeneralTableRow')
      try {
        if (Rows.length > 0) {
          Rows.forEach(row => {
            row.style.opacity = 0
          })
          setTimeout(() => {
            Rows.forEach(row => {
              row.style.opacity = 1
            })
          }, 250)
        }
      } catch (error) {
      }
      if (JSON.stringify(this.sortElment.name) === JSON.stringify(name)) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.sortElment.name = name
      }
    },
    ResetSelect () {
      var slaected = this.SelectedOrders
      this.CookiesDataOrders.forEach(element => {
        if (slaected.length === this.FilterdTable.length) {
          this.$store.state.CookiesMemory['O_' + element.ID + '_Select'] = false
        } else {
          this.$store.state.CookiesMemory['O_' + element.ID + '_Select'] = true
        }
      })
    },
    SelectAll () {
      this.PageOrders.forEach(element => {
        if (!element.Payment_recived_From_Driver & !element.Payment_Delivered_To_Cusotmer) {
          this.$store.state.CookiesMemory['O_' + element.ID + '_Select'] = true
        }
      })
    },
    DeSelectAll () {
      this.CookiesDataOrders.forEach(element => {
        this.$store.state.CookiesMemory['O_' + element.ID + '_Select'] = false
      })
    },
    ChangeSelectedStatus (newStatus, Order = '') {
      this.Console(newStatus)
      this.Console(this.SameStatus)
      if (newStatus === this.SameStatus & newStatus !== 'RemoveIssue') {
        return
      }
      var ApiUrlMapper = {
        Ordered: 'Order/UpdateStatus?NewStatus=14',
        Collecting: 'Order/UpdateStatus?NewStatus=1',
        Collected: 'Order/UpdateStatus?NewStatus=2',
        Received: 'Order/UpdateStatus?NewStatus=3',
        OnDelivery: 'Order/UpdateStatus?NewStatus=4',
        Redirected: 'Order/UpdateStatus?NewStatus=7',
        Delayed: 'Order/UpdateStatus?NewStatus=6',
        Delivered: 'Order/UpdateStatus?NewStatus=10',
        Item_Lost: 'Order/UpdateStatus?NewStatus=8',
        AssignDriver: 'Order/AssignOrdersToDriver',
        Canceled: 'Order/UpdateStatus?NewStatus=5',
        HasIssue: 'Order/UpdateStatus',
        RemoveIssue: 'Order/UpdateStatus?HasIssue=2&IssueNote=',
        Returned: 'Order/UpdateStatus?NewStatus=12',
        Delete: 'Order/UpdateStatus?NewStatus=15'
      }
      var DataToSend = {
        Driver: false,
        Date: false,
        Items: false,
        Money: false
      }
      var OrdersIDs = []
      if (Order !== '') {
        OrdersIDs = [Order.ID]
      } else {
        OrdersIDs = this.AttributeArray(this.SelectedOrders, 'ID')
      }
      DataToSend.NewStatus = newStatus
      DataToSend.OldStatus = this.SameStatus
      DataToSend.OrdersList = OrdersIDs
      if (this.SelectedOrders.length > 0) {
        DataToSend.AmountTotal = this.SelectedOrders[0].AmountTotal
        DataToSend.CustomerID = this.SelectedOrders[0].Customer.CustomerID
        DataToSend.OlderAreaFees = this.SelectedOrders[0].Area.Fees
      } else {
        DataToSend.AmountTotal = ''
        DataToSend.CustomerID = ''
        DataToSend.OlderAreaFees = ''
      }
      DataToSend.ApiUrl = ApiUrlMapper[newStatus]
      this.OpenDialog('OrderAction', this.LanguageSelector('Changing Orders Status', 'تعديل حالة الطلبات'), DataToSend) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    RemoveOrder (Order) {
      this.OpenDialog('DeleteDialog', this.LanguageSelector('Deleting Order', 'حذف موظف'), ['Order/Delete?Id=' + Order.Id, Order.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    PrintBadge (Order) {
      this.OpenDialog('OrderBagde', this.LanguageSelector('', ''), Order, { header: false, background: 'none' }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    LoadOrderHis (OrderID) {
      this.Get('order/getorderhis?OrderID=' + OrderID).then(response => {
        var SortedRes = response.sort(this.OrderHisSorting)
        this.DeepChanger(this.OrdersHis, ['Order_' + OrderID], SortedRes)
      }, error => {
        error.CookiesError = 'Error in Reading Order Status'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    OverOrder (OrderID) {
      if (OrderID === 0 || OrderID === undefined) {
        return
      }
      this.CurrentOrderHover = OrderID
      setTimeout((x = OrderID) => {
        if (this.CurrentOrderHover === x) {
          this.LoadOrderHis(OrderID)
        }
      }, 400)
    },
    LeftOrder (OrderID) {
      this.CurrentOrderHover = ''
    },
    SelectedOrderHider (OrderID) {
      if (this.ObjectFinder(this.SelectedOrders, ['ID'], OrderID)) {
        return true
      }
      return false
    },
    BarCodeKeyPressed () {
      if ((event.which) === 13 & this.BarCode !== '') {
        var ReadNow = this.ObjectFinder(this.CookiesDataOrders, ['BarCode'], this.BarCodeReader.toString())
        if (ReadNow !== undefined) {
          this.$store.state.CookiesMemory['O_' + ReadNow.ID + '_Select'] = true
          this.BarCodeReader = ''
        } else {
          this.BarCodeReader = ''
        }
      }
    },
    BarCodeClicked () {
      this.BarCodeReader = ''
    },
    BarCodeBlurred () {
      this.$refs.BarCodeReader.focus()
    },
    OpenSelectScanner () {
      this.ScanningSelect = true
      setTimeout(() => {
        this.$refs.BarCodeReader.focus()
      }, 250)
      this.$refs.BarCodeReader.focus()
    },
    AllFilterSelector (value) {
      this.StatusMapper('', 'list').forEach(status => {
        ['Done', 'Closed', 'DriverPaid', 'CustomerPaid'].indexOf(status) >= 0 ? this.ArchivedOrders && (this.$store.state.CookiesMemory['OrdersFilter_' + status] = value) : !this.ArchivedOrders && (this.$store.state.CookiesMemory['OrdersFilter_' + status] = value)
      })
    },
    ChangePage (PageNumber) {
      var Rows = document.getElementsByClassName('CookiesGeneralTableRow')
      try {
        if (Rows.length > 0) {
          Rows.forEach(row => {
            row.style.opacity = 0
          })
          setTimeout(() => {
            this.CurrentPage = PageNumber
            Rows.forEach(row => {
              row.style.opacity = 1
            })
          }, 250)
        } else {
          this.CurrentPage = PageNumber
        }
      } catch (error) {
        this.CurrentPage = PageNumber
      }
    },
    ChangeOrders (Tab) {
      this.OrdersHis = {}
      var Bool = Tab.Value
      Bool ? (this.ReadArchivedOrders()) : (this.ReadOrders())
      var Rows = document.getElementsByClassName('CookiesGeneralTableRow')
      try {
        if (Rows.length > 0) {
          Rows.forEach(row => {
            row.style.opacity = 0
          })
          setTimeout(() => {
            this.ArchivedOrders = Bool
            Rows.forEach(row => {
              row.style.opacity = 1
            })
          }, 250)
        } else {
          this.ArchivedOrders = Bool
        }
      } catch (error) {
        this.ArchivedOrders = Bool
      }
    },
    DatesChanged () {
      this.ReadArchivedOrders()
    },
    RemoveIssue (x) {
      this.ChangeSelectedStatus(x[0], x[1])
    },
    SearchNow () {
      this.CurrentSearch = this.Search
    },
    SearchKey () {
      if ((event.which) === 13) {
        this.CurrentSearch = this.Search
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadOrders()
      }
    },
    NotSelectedOrders: function (newVal, oldVal) { // watch it
      if (this.NotSelectedOrders.length === 0) {
        this.CurrentPage = 1
      } else if (this.CurrentPage > Math.ceil(this.NotSelectedOrders.length / this.OrdersPerPage)) {
        this.CurrentPage = Math.ceil(this.NotSelectedOrders.length / this.OrdersPerPage)
      }
    }
  }
}
</script>
<style scoped>

.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 0.85em;
  height: 80vh;
}
.CookiesMainView{
  padding-top: 0.8em;
  padding-left: 1em;
  padding-right: 1em;
}
.StatusBar{
  top: 5.2em;
}
@media (max-width: 500px) {
  .BigHeader{
    font-size: 1.6em !important;
  }
  .StatusBar{
    font-size: 1em !important;
  }
  .StatusBar{
    top: 18.5em;
  }
  .BigPlaceholder{
    font-size: 0.7vw;
  }
}
@media (max-width: 2000px) {
  .BigPlaceholder{
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder{
    font-size: 0.7vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}

.CookiesGeneralTableRow{
  position: relative;
}
tr{
  padding: 2em;
}
.CookiesGeneralTableRow:hover > .OrderTimeLine > div{
  /* height: 100%; */
  transform: scaleY(1);
  opacity: 1;
}
.CookiesGeneralTableRow:hover > .OrderTimeLine > div > *{
  font-size: 1em;
}
.CookiesGeneralTableRow:hover > .OrderTimeLine > div > .TimeLineLoading{
  display: block;
}
.CookiesGeneralTableRow:hover{
  z-index: 5;
}
.OrderTimeLine{
  position: absolute;
  width: 80%;
  left:10% ;
  bottom: -4em;
  height: 4em;
  z-index:500;
  pointer-events: none;
}
.OrderTimeLine > div > *{
  font-size: 0em;
  transition: 0.2s font-size 0.2s ease;
}
.OrderTimeLine > div > .TimeLineLoading{
  display: none;
}
.OrderTimeLine > div{
  pointer-events: all;
  height: 100%;
  transform: scaleY(0);
  width: 100%;
  background: v-bind('$store.getters.Theme.BG6');
  position: relative;
  border-radius: 0 0 var(--rad2) var(--rad2);
  display: flex;
  transition: 0.3s transform 0s ease, 0.2s opacity 0s ease ;
  font-size: 1em;
  justify-content: center;
  justify-items: center;
  /* overflow: hidden; */
  /* filter: drop-shadow(var(--shadow1)); */
  opacity: 0;
  border-top: solid 1px v-bind('$store.getters.Theme.Shade1');
}
.timeline{
  margin: auto;
  display: flex;
  font-size: inherit;
  width: 80%;
}
.line{
  margin: auto -0.5em;
  width: 8em;
  flex: 5;
  height: 0.4em;
  border: solid 0.4em v-bind('$store.getters.Theme.shade3');
  background: v-bind('$store.getters.Theme.Shade1');
  filter: drop-shadow(0.2em 0.2em 0.8em rgba(0, 0, 0, 0.3));
  font-size: inherit;
}
.OrderStage{
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: v-bind('$store.getters.Theme.BG2');
  z-index: 5;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease;
  display: flex;
  font-size: inherit;
  position: relative;
  cursor: pointer;
}
.OrderStage > *{
  margin: auto;
  width: 70%;
  height: 70%;
  transition: transform 0.4s ease;
}
.OrderStage:hover{
  /* width: 3.5em;
  height: 3.5em; */
  transform: scale(1.2);
  /* filter: drop-shadow(var(--shadow0)); */
}
.OrderStage::before{
  opacity: 0.7;
  /* background: v-bind('$store.getters.Theme.BG6'); */
  filter: drop-shadow(var(--shadow1));
  content: '';
  background: v-bind('$store.getters.Theme.BG2');
  border-radius: 20em;
  position: absolute;
  inset: 0;
  z-index: -8;
  transition: opacity 0.4s ease;
}
.OrderStage:hover::before{
  opacity: 1;
}
.OrderStage:hover > *{
  transform: scale(1.1);
}
.ActiveLines{
  background: v-bind('$store.getters.Theme.UserColors.third');
}
.CookiesGeneralTable td:nth-last-child(2) { border-radius: v-bind('DirectionStyler.last'); }
.ActionButtons{
  display: flex;
  border-radius: 100px;
  padding: 0.5em 1em;
  min-height:2.3em;
  margin: auto 0.2em;
  background: v-bind('$store.getters.Theme.BG6');
  font-weight: 600;
  filter: drop-shadow(var(--shadow0));
  transition:0.2s ease;
  transition-property: filter,transform;
  /* margin: auto; */
}
.ActionButtons:hover{
  filter: drop-shadow(var(--shadow1));
}
.ActionButtons > *{
  padding-left: 0.2em;
  padding-right: 0.2em;
}
.FilterArea{
  background: v-bind('$store.getters.Theme.BG2');
  border-radius: var(--rad2);
  display: flex;
  flex-wrap: wrap;
  margin-left: 1em;
  margin-right: 1em;
}
.FilterArea > div {
  width: 12em;
  padding: 1em;
  opacity: 1;
}
.FilterButton{
  display: none;
}
@media (max-width: 800px){
  .FilterArea{
    position: absolute;
    min-height: 100%;
    width: 100%;
    right: 0;
    z-index: 55;
    font-size: 4vw !important;
    overflow-y: scroll;
    margin: 0;
    transition: width,overflow,opacity 1s ease-in-out;
  }
  .FilterButton{
    display: block;
  }
  .FilterHidden{
    width: 0% !important;
    overflow: hidden !important;
    font-size: 0em !important;
    opacity: 0;
    pointer-events: none;
  }
  .BigPlaceholder{
    font-size: 3vw;
  }
  .PhoneHider{
    display: none !important;
  }
  .CookiesGeneralTable > tr{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .CookiesGeneralTableRow{
    padding: 0;
    border-bottom: solid 1px v-bind('$store.getters.Theme.BG4') !important;
  }
  .CookiesGeneralTable td{
    border: none !important;
    display: block;
  }
  .OrdersHeader{
    font-size: 3vw;
    width: 100%;
    margin-top: 0.5em;
  }
  .CookiesSearchInputContainer{
    font-size: 3vw;
  }
}
.CookiesTablePager > div{
  z-index: 55;
  padding: 0em;
  position: absolute;
  bottom: 0;
  width:100%;
  direction: ltr;
  font-size: 0.85em;
}
.CookiesTablePager > div > div{
  margin-right: 25%;
  margin-left: 25%;
  padding: 0.5em;
  border-top-left-radius: 50em;
  border-top-right-radius: 50em;
  background: v-bind('$store.getters.Theme.BG6');
  filter: drop-shadow(var(--shadow1));
  display: flex;
  user-select: none;
}
.CurrentPage{
  padding: 0em 0.55em !important;
  border-radius: 5em;
  transform: scale(1.1);
  background-color: v-bind('$store.getters.Theme.UserColors.third');
}
.CookiesGeneralTableRow{
  transition: opacity 0.25s ease;
}
.OrdersNumber{
  width: unset !important;
}
.OrdersNumber > span{
  margin: 0.3em;
  border-radius: 10em;
  font-size: 1.3em;
  padding: 0.3em 0.8em;
  background: v-bind('$store.getters.Theme.BG6');
  filter: drop-shadow(var(--shadow1));
}

</style>
